import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import axiosAPI from '../../../../utils/client/axiosAPI';
import style from './single-order.module.scss';
import { FaFileDownload as DownloadIcon } from 'react-icons/fa';
import cx from 'classnames';
import { useMemo } from 'react';
import { Product } from '../../../../models/Product/Product';
import { currencyFormat } from '../../../../utils/helpers/helpers';
import {
  DocumentDownloadType,
  downloadDocument,
  getSingleOrder,
  OrderStatus,
  OrderResponse
} from './queries';

const OrderStatusClasses: { [key in OrderStatus]: string } = {
  [OrderStatus.PENDING]: 'cs-pending-status',
  [OrderStatus.ACCEPTED]: 'cs-accepted-status',
  [OrderStatus.DECLINED]: 'cs-declined-status',
  [OrderStatus.CLIENT_ACCEPTED]: 'cs-accepted-status',
  [OrderStatus.CLIENT_DECLINED]: 'cs-declined-status',
  [OrderStatus.RENTED]: 'cs-rented-status',
  [OrderStatus.RETURNED]: 'cs-returned-status'
};

const StatusActions: {
  [key in OrderStatus]?: Array<{ status: OrderStatus; label: string }>;
} = {
  [OrderStatus.PENDING]: [
    { status: OrderStatus.ACCEPTED, label: 'Accept' },
    { status: OrderStatus.DECLINED, label: 'Decline' }
  ],
  [OrderStatus.ACCEPTED]: [
    {
      status: OrderStatus.CLIENT_ACCEPTED,
      label: 'Ova ponuda nije jos prihvacena od klijenta.'
    }
  ],
  [OrderStatus.CLIENT_ACCEPTED]: [
    { status: OrderStatus.RENTED, label: 'Rent' }
  ],
  [OrderStatus.CLIENT_DECLINED]: [
    {
      status: OrderStatus.CLIENT_DECLINED,
      label: 'Ponuda je odbijena od strane klijenta.'
    }
  ],
  [OrderStatus.RENTED]: [{ status: OrderStatus.RETURNED, label: 'Return' }]
};

function SingleOrder() {
  const { id } = useParams<{ id: string }>();

  const { data: order, refetch } = useQuery<OrderResponse>({
    queryKey: ['order', id],
    queryFn: () => getSingleOrder(id ?? ''),
    enabled: typeof id !== 'undefined'
  });

  const totalPrice: number = useMemo(() => {
    if (!order || !order.products) {
      return 0;
    }

    return (
      order.products.reduce<number>(
        (acc, product) => acc + (product.price ?? 0),
        0
      ) * (order.price_factor ?? 1)
    );
  }, [order]);

  const updateOrderStatus = (status: OrderStatus) => {
    axiosAPI
      .put(`/orders/${id}`, {
        status
      })
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="cs-inner-container">
      <h1 className="cs-title mb-2">Narudžbina broj {order?.id}</h1>
      <div className={style.orderStatus}>
        <span className={style.text}>Status narudžbine:</span>
        <div
          className={cx(
            style.value,
            order?.status
              ? OrderStatusClasses[order.status]
              : 'cs-default-status-tab',
            'cs-status-tab'
          )}
        >
          {order?.status}
        </div>
      </div>
      <div className={style.singleOrderHeader}>
        <div className={style.info}>
          <h2 className={style.subtitle}>Informacije o porudzbini:</h2>

          <div className={style.item}>
            <span className={style.label}>Porudžbina kreirana na:</span>
            <span className={style.value}>
              {order?.reservation_start
                ? typeof order.reservation_start === 'string'
                  ? order.reservation_start
                  : order.reservation_start.toLocaleDateString() // Format Date to readable string
                : 'N/A'}
            </span>
          </div>
          <div className={style.item}>
            <div className={style.label}>Poručilac:</div>
            <div className={style.value}>{order?.full_name}</div>
          </div>
          <div className={style.item}>
            <div className={style.label}>Email Adresa:</div>
            <div className={style.value}>{order?.email}</div>
          </div>
          {order?.type === 'company' && (
            <>
              <div className={style.item}>
                <div className={style.label}>Ime Kompanije:</div>
                <div className={style.value}>{order?.company_name}</div>
              </div>
              <div className={style.item}>
                <div className={style.label}>Maticni broj:</div>
                <div className={style.value}>{order?.company_id_number}</div>
              </div>
              <div className={style.item}>
                <div className={style.label}>PIB:</div>
                <div className={style.value}>{order?.company_tax_number}</div>
              </div>
            </>
          )}
          <div className={style.item}>
            <div className={style.label}>Početak Rezervacije:</div>
            <div className={style.value}>
              {order?.reservation_start
                ? typeof order.reservation_start === 'string'
                  ? order.reservation_start
                  : order.reservation_start.toLocaleDateString() // Convert Date to string
                : 'N/A'}
            </div>
          </div>
          <div className={style.item}>
            <div className={style.label}>Kraj Rezervacije:</div>
            <div className={style.value}>
              {order?.reservation_end
                ? typeof order.reservation_end === 'string'
                  ? order.reservation_end
                  : order.reservation_end.toLocaleDateString() // Convert Date to string
                : 'N/A'}
            </div>
          </div>
          <div className={style.item}>
            <div className={style.label}>Dodatne napomene:</div>
            <div className={style.value}>
              {order?.note ||
                'Nije ostavljena nikakva dodatna napomena uz narudzbinu.'}
            </div>
          </div>
        </div>
        <div className={style.documentation}>
          <h2 className={style.subtitle}>Dokumentacija za preuzimanje:</h2>

          <div className={style.documentItem}>
            <p className={style.label}>Kalkulacija cene:</p>
            <button
              onClick={() =>
                downloadDocument({
                  type: DocumentDownloadType.CALCULATION,
                  id: id ?? 0
                })
              }
            >
              <DownloadIcon />
            </button>
          </div>
          <div className={style.documentItem}>
            <p className={style.label}>Revers:</p>
            <button
              onClick={() =>
                downloadDocument({
                  type: DocumentDownloadType.GET_PRODUCTS,
                  id: id ?? 0
                })
              }
            >
              <DownloadIcon />
            </button>
          </div>
          <div className={style.documentItem}>
            <p className={style.label}>Povraćaj reversa:</p>
            <button
              onClick={() =>
                downloadDocument({
                  type: DocumentDownloadType.RETURN_PRODUCTS,
                  id: id ?? 0
                })
              }
            >
              <DownloadIcon />
            </button>
          </div>
        </div>
      </div>
      <div className={style.orderActions}>
        {order?.status &&
          StatusActions[order.status]?.map(({ status, label }) => {
            if (
              order.status === OrderStatus.ACCEPTED ||
              order.status === OrderStatus.CLIENT_DECLINED
            ) {
              return (
                <p
                  key={status}
                  className={cx(
                    order.status === OrderStatus.ACCEPTED
                      ? style.acceptedText
                      : style.declinedText
                  )}
                >
                  {label}
                </p>
              );
            } else {
              return (
                <button
                  key={status}
                  onClick={() => {
                    updateOrderStatus(status);
                  }}
                  className={cx(
                    OrderStatusClasses[status] || 'cs-default-status',
                    'cs-status-button'
                  )}
                >
                  {label}
                </button>
              );
            }
          })}
      </div>

      <h2 className="cs-subtitle mt-4">Sadržaj porudžbine</h2>

      <div className={style.orderList}>
        <div className={style.orderHeader}>
          <span>SKU</span>
          <span>Naziv</span>
          <span>Cena [RSD]</span>
        </div>
        <div className={style.orderBody}>
          {order?.products?.map((product: Product) => (
            <div key={product.id} className={style.orderItem}>
              <div className={style.itemId}>{product.sku}</div>
              <div className={style.itemInfo}>
                <div className={style.itemImage}>
                  {product.images && product.images.length > 0 ? (
                    <img src={product.images[0]} alt={product.name} />
                  ) : (
                    <span>No Image Available</span>
                  )}
                </div>
                <div className={style.itemName}>{product.name}</div>
              </div>
              <div className={style.itemPrice}>
                {product.price * (order?.price_factor ?? 1)}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={style.orderFooter}>
        <div className={style.orderSubtotal}>
          <div className={style.label}>Predračun:</div>
          <div className={style.value}>{currencyFormat(totalPrice)}</div>
        </div>
        <div className={style.orderPdv}>
          <div className={style.label}>Ukupan PDV:</div>
          <div className={style.value}>{currencyFormat(totalPrice * 0.2)}</div>
        </div>
        <div className={style.orderTotal}>
          <div className={style.label}>Ukupno:</div>
          <div className={style.value}>{currencyFormat(totalPrice * 1.2)}</div>
        </div>
      </div>
    </div>
  );
}

export default SingleOrder;
