import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  Image,
  ImageWithZoom,
  Slide,
  Slider
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import cx from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Product } from '../../../models/Product/Product';
import collect, { Collection } from 'collect.js';
import { ProductAttribute } from '../../../models/Product/contracts';
import style from './single-product.module.scss';
import AttributeGroup from '../../../Shop/components/Attributes/AttributeGroup';
import { ProductType } from '../../../models/Product/contracts';
import { MdQrCode } from 'react-icons/md';
import QRCode from 'qrcode.react';
interface ProductDetailsProps {
  product: Product;
  setSelectedProduct: (product: Product) => void;
}

function CompositeProduct({}: ProductDetailsProps) {
  return <>CompositeProduct</>;
}

function VariableProduct({ product, setSelectedProduct }: ProductDetailsProps) {
  const [selectedAttributeValues, setSelectedAttributeValues] = useState<{
    [key: string]: number;
  }>({});

  const attributes = useMemo(
    () =>
      collect(product.attributes ?? [])
        .groupBy<Collection<ProductAttribute>, string>('name')
        .map<ProductAttribute[]>((attributes) => attributes.toArray()),
    [product]
  );

  const variations = useMemo(() => {
    const variationMap = new Map<string, any>();
    product?.variations?.forEach((variation) => {
      variationMap.set(
        variation.attributes
          ?.map(({ id }: any) => id)
          .sort()
          .join('-') ?? '',
        variation
      );
    });
    return variationMap;
  }, [product?.variations]);

  useEffect(() => {
    const key = Object.values(selectedAttributeValues).sort().join('-');
    if (!variations.has(key)) {
      // No combination, restore previous state or something?
      return;
    }
    setSelectedProduct(variations.get(key));
  }, [selectedAttributeValues]);

  return (
    <>
      {attributes
        .map((attributes, key) => (
          <AttributeGroup
            key={key}
            title={key}
            attributes={attributes}
            isCard={false}
            onSelect={({ name, id }) => {
              setSelectedAttributeValues((attributeValues) => {
                return {
                  ...attributeValues,
                  [name]: id
                };
              });
            }}
          />
        ))
        .toArray()}
    </>
  );
}

function SimpleProduct({ product, setSelectedProduct }: ProductDetailsProps) {
  useEffect(() => {
    setSelectedProduct(product);
  }, [product]);

  const attributes = useMemo(
    () =>
      collect(product.attributes ?? [])
        .groupBy<Collection<ProductAttribute>, string>('name')
        .map<ProductAttribute[]>((attributes) => attributes.toArray()),
    []
  );

  return (
    <>
      {attributes
        .map((attributes, key) => (
          <AttributeGroup
            key={key}
            title={key}
            attributes={attributes}
            isCard={true}
          />
        ))
        .toArray()}
    </>
  );
}

function ProductDetailsFactory({ product, ...props }: ProductDetailsProps) {
  switch (product.type) {
    case ProductType.SIMPLE:
      return <SimpleProduct product={product} {...props} />;
    case ProductType.VARIABLE:
      return <VariableProduct product={product} {...props} />;
    // case 'composite':
    //   return <CompositeProduct product={product} {...props} />;
  }

  throw new Error(
    `Factory Error: Failed to create product component of type "${product?.type}"`
  );
}

function SingleProduct() {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { id } = useParams();
  const { data: product, isLoading } = useQuery(
    ['product', id],
    () => Product.get(id as string),
    {
      enabled: !!id
    }
  );

  const downloadQRCode = (name: string) => {
    // @ts-ignore
    const qrCodeURL = document
      .getElementById('qrCode')
      // @ts-ignore
      .toDataURL('image/png')
      // @ts-ignore
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    // @ts-ignore
    aEl.href = qrCodeURL;
    aEl.download = name + '.png';
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <div className="cs-content">
      <div className={style.wrapper}>
        <div className={style.gallery}>
          <CarouselProvider
            visibleSlides={1}
            totalSlides={5}
            naturalSlideWidth={300}
            naturalSlideHeight={400}
            className={style.productCarousel}
          >
            <div className={style.thumbs}>
              {product?.images?.map((item, index) => (
                <Dot
                  key={index}
                  slide={index}
                  className={cx(
                    style.paginationButton,
                    currentSlide === index ? style.selected : ''
                  )}
                  onClick={() => setCurrentSlide(index)}
                >
                  <Image src={item} hasMasterSpinner={false} />
                </Dot>
              ))}
            </div>
            <Slider className={style.mainSlider}>
              {product?.images?.map((item, index) => (
                <Slide key={index} index={index}>
                  <ImageWithZoom src={item} />
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </div>
        <div className={style.info}>
          <h4 className={style.subtitle}>{selectedProduct?.category?.name}</h4>
          <div className={style.titleContainer}>
            <h1 className={style.title}>{selectedProduct?.name}</h1>
            <p>{selectedProduct?.sku}</p>

            <button
              className="cs-button-primary"
              onClick={() => {
                downloadQRCode(selectedProduct?.name || 'default');
              }}
            >
              <MdQrCode />
              <QRCode
                id="qrCode"
                size={240}
                value={selectedProduct?.sku || ''}
              />
            </button>
          </div>
          <p className={style.description}>{selectedProduct?.sku}</p>

          <div className={style.price}>
            {selectedProduct?.price}&nbsp;
            {process.env.REACT_APP_DEFAULT_CURRENCY}
          </div>
          <div className={style.productDetails}>
            {!!product && (
              <ProductDetailsFactory
                product={product}
                setSelectedProduct={setSelectedProduct}
              />
            )}
          </div>
        </div>
      </div>
      <h2 className="cs-subtitle mb-3">Karakteristike Proizvoda</h2>
      <div className={style.featuresWrapper}>
        {product?.features?.map((item: any) => (
          <div key={item.id} className={style.featuresItem}>
            <div className={style.sidebarTitle}>{item.name}:</div>
            <div className={style.sidebarValue}>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SingleProduct;
