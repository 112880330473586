import { Product } from '../../../../models/Product/Product';
import { UserCustomerType } from '../../../../models/User/User';
import axiosAPI from '../../../../utils/client/axiosAPI';

export enum DocumentDownloadType {
  CALCULATION = 'calculation',
  GET_PRODUCTS = 'get-products',
  RETURN_PRODUCTS = 'return-products'
}

export interface DownloadDocumentPayload {
  id: number | string;
  type: DocumentDownloadType;
}

export async function downloadDocument(
  payload: DownloadDocumentPayload
): Promise<void> {
  try {
    // Send POST request to download document
    const response = await axiosAPI.post(`/download-document`, payload, {
      responseType: 'blob' // Set the response type to blob for downloading files
    });

    // Create a temporary URL for the downloaded file
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', generateFilename(payload)); // Generate a descriptive filename
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link element and revoking the URL
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);

    // Optionally, show a success message to the user
    alert('Document downloaded successfully');
  } catch (error) {
    console.error('Error downloading document:', error);
    // Optionally, show an error message to the user
    alert('Failed to download document. Please try again later.');
    throw new Error('Failed to download document');
  }
}

function generateFilename(payload: DownloadDocumentPayload): string {
  // Customize the filename based on the document type and ID
  return `${payload.type}-${payload.id}.pdf`;
}

export enum OrderStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  CLIENT_ACCEPTED = 'client-accepted',
  CLIENT_DECLINED = 'client-declined',
  RENTED = 'rented',
  RETURNED = 'returned'
}

export type OrderResponse = {
  id: number;
  status: OrderStatus;
  full_name: string;
  number_of_order: number;
  type: UserCustomerType;
  company_name: string;
  company_tax_number: number | string;
  company_id_number: number | string;
  email: string;
  state: string;
  city: string;
  zip: number | '';
  address: string;
  phone?: string;
  note?: string;
  washing_fee: boolean;
  reservation_start: Date | string;
  reservation_end: Date | string;
  price_factor?: number;
  products: Product[];
};

export async function getSingleOrder(id: string) {
  const { data } = await axiosAPI.get<OrderResponse>(`/orders/${id}`);
  return data;
}
